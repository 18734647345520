import { useState, useEffect } from "react";
import { get } from "./apiUtils"; 
const ADMIN_ROLES = ["596402057758375966", "597495855100067850"];
const STAFF_ROLES = ["597496371729268746"];
export const useAdminStatus = (discordId: string | null) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isStaff, setIsStaff] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAdminStatus = async () => {
            if (!discordId) {
                setIsAdmin(false);
                setIsLoading(false);
                return;
            }

            try {
                const response = await get<{ roles: string[] }>(`user-roles/${discordId}`);
                
                if (response.success) {
                    setIsAdmin(response.data?.roles.some((role: string) => ADMIN_ROLES.includes(role)) || false);
                } else {
                    console.error("Error checking admin status:", response.error);
                    setIsAdmin(false);
                }
            } catch (error) {
                console.error("Error checking admin status:", error);
                setIsAdmin(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkAdminStatus();
    }, [discordId]);

    useEffect(() => {
        if (!discordId) {
            setIsAdmin(false);
            setIsLoading(false);
            return;
        }

        const checkStaffStatus = async () => {
            try {
                const response = await get<{ roles: string[] }>(`user-roles/${discordId}`);
                
                if (response.success) {
                    setIsStaff(response.data?.roles.some((role: string) => STAFF_ROLES.includes(role)) || false);
                } else {
                    console.error("Error checking staff status:", response.error);
                    setIsStaff(false);
                }
            } catch (error) {
                console.error("Error checking staff status:", error);
                setIsStaff(false);
            }
        };

        checkStaffStatus();
    }, [discordId]);

    return { isAdmin, isLoading, isStaff };
};
