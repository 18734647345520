import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Text,
  Spinner,
  Button,
  Flex,
  useColorModeValue,
  VStack,
  HStack,
  useBreakpointValue,
  Input,
} from '@chakra-ui/react';
import { get } from '../../utils/apiUtils';

interface Kick {
  _id: string;
  nick: string;
  reason: string;
  date: string;
}

const AdminKicks: React.FC = () => {
  const [kicks, setKicks] = useState<Kick[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const isMobile = useBreakpointValue({ base: true, md: false });

  const fetchKicks = async (pageNum: number) => {
    setIsLoading(true);
    try {
      const response = await get<Kick[]>(`kicks?page=${pageNum}`);
      if (!response.ok) {
        throw new Error('Failed to fetch kicks');
      }
      const data: Kick[] = await response.json() as Kick[];
      if (data.length < 10) {
        setHasMore(false);
      }
      return data;
    } catch (error) {
      console.error('Error fetching kicks:', error);
      setError('Error al cargar los kicks. Por favor, intenta de nuevo más tarde.');
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadInitialKicks = async () => {
      const initialKicks = await fetchKicks(1);
      setKicks(initialKicks);
    };
    loadInitialKicks();
  }, []);

  const loadMore = async () => {
    const nextPage = page + 1;
    const newKicks = await fetchKicks(nextPage);
    setKicks(prevKicks => [...prevKicks, ...newKicks]);
    setPage(nextPage);
  };

  const handleSearch = async () => {
    if (searchTerm) {
      setIsLoading(true);
      try {
        const response = await fetch(`http://localhost:8080/api/kicks/${searchTerm}`);
        if (!response.ok) {
          throw new Error('Kicks not found');
        }
        const data = await response.json();
        setKicks(data);
        setHasMore(false);
      } catch (error) {
        console.error('Error searching kicks:', error);
        setError('No se encontraron kicks para ese nick.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box bg={bgColor} borderRadius="lg" boxShadow="md" p={4}>
      <Heading as="h2" size="lg" mb={6} color={textColor}>
        Administración de Kicks
      </Heading>
      <Flex mb={6} direction={isMobile ? "column" : "row"} gap={4}>
        <Input
          placeholder="Buscar por nick"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          width={isMobile ? "full" : "200px"}
        />
        <Button onClick={handleSearch} colorScheme="orange" width={isMobile ? "full" : "auto"}>
          Buscar
        </Button>
      </Flex>
      {isLoading && kicks.length === 0 ? (
        <Flex justifyContent="center" mt={4}>
          <Spinner size="xl" color="orange.500" />
        </Flex>
      ) : error ? (
        <Text color="red.500">{error}</Text>
      ) : kicks.length === 0 ? (
        <Text>No se encontraron kicks.</Text>
      ) : (
        <>
          {isMobile ? (
            <VStack spacing={4} align="stretch">
              {kicks.map((kick) => (
                <Box key={kick._id} p={4} borderWidth={1} borderRadius="md">
                  <VStack align="start" spacing={2}>
                    <Text fontWeight="bold">Nick: {kick.nick}</Text>
                    <Text>Razón: {kick.reason}</Text>
                    <Text>Fecha: {new Date(kick.date).toLocaleString()}</Text>
                  </VStack>
                </Box>
              ))}
            </VStack>
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Nick</Th>
                    <Th>Razón</Th>
                    <Th>Fecha</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {kicks.map((kick) => (
                    <Tr key={kick._id}>
                      <Td>{kick.nick}</Td>
                      <Td>{kick.reason}</Td>
                      <Td>{new Date(kick.date).toLocaleString()}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {hasMore && (
            <Flex justifyContent="center" mt={6}>
              <Button onClick={loadMore} colorScheme="orange" isLoading={isLoading}>
                Cargar más
              </Button>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default AdminKicks;