import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '../auth/supabaseClient';
import { User } from '@supabase/supabase-js';

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  discordId: string | null;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [discordId, setDiscordId] = useState<string | null>(null);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      updateAuthState(session?.user || null);
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      updateAuthState(session?.user || null);
      if (event === 'SIGNED_IN') {
        const redirectTo = localStorage.getItem('redirectAfterLogin');
        if (redirectTo) {
          window.location.href = redirectTo;
          localStorage.removeItem('redirectAfterLogin');
        }
      }
    });

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  const updateAuthState = (user: User | null) => {
    setUser(user);
    setIsAuthenticated(!!user);
    setDiscordId(user?.user_metadata?.sub || null);
  };

  const login = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.startsWith('/tickets-logs/')) {
      localStorage.setItem('redirectAfterLogin', currentPath);
    } else {
      localStorage.setItem('redirectAfterLogin', '/');
    }
    await supabase.auth.signInWithOAuth({
      provider: 'discord',
      options: {
        redirectTo: `${window.location.origin}/auth/callback`
      }
    });
  };

  const logout = async () => {
    try {
      await supabase.auth.signOut();
      updateAuthState(null);
      window.location.href = '/';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, discordId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth debe ser usado dentro de un AuthProvider');
  }
  return context;
};

export default AuthContext;