import React, { useState, useEffect, useMemo, forwardRef } from "react"
import {
  Box,
  Heading,
  Text,
  Spinner,
  Button,
  Flex,
  useColorModeValue,
  VStack,
  HStack,
  Input,
  Select,
  Badge,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Container,
  SimpleGrid,
  Icon,
  Tooltip,
  InputGroup,
  InputLeftElement,
  BoxProps,
} from "@chakra-ui/react"
import { motion, isValidMotionProp, MotionProps } from "framer-motion"
import { FiSearch, FiCalendar, FiClock, FiEye, FiUser, FiUserCheck, FiUserX, FiChevronDown } from "react-icons/fi"
import { get } from "../../utils/apiUtils"

interface StaffMember {
  _id: string
  username: string
  userId: string
  freeze: boolean
  reduce: boolean
  days?: number
  startTime?: number
  endTime?: number
  reduceDays?: number
  startReduce?: number
  endReduce?: number
  ausenciaMessage?: string[]
  reduceMessage?: string[]
}

type MotionBoxProps = Omit<MotionProps, keyof BoxProps> & BoxProps & {
  as?: React.ElementType;
};

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);

const AdminStaff: React.FC = () => {
  const [allStaff, setAllStaff] = useState<StaffMember[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [statusFilter, setStatusFilter] = useState("all")

  const accentColor = useColorModeValue("orange.500", "orange.300")
  const bgColor = useColorModeValue("gray.50", "gray.900")
  const cardBgColor = useColorModeValue("white", "gray.800")
  const textColor = useColorModeValue("gray.800", "gray.100")

  const fetchStaff = async () => {
    setIsLoading(true)
    try {
      const response = await get<StaffMember[]>("staff")
      if (response.success && response.data) {
        const staffWithDiscordId = response.data.filter(member => member.userId)
        setAllStaff(staffWithDiscordId)
      } else {
        setError(response.error || "Error al cargar el personal.")
      }
    } catch (err) {
      setError("Error al cargar el personal.")
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchStaff()
  }, [])

  const isDateValid = (start: number, end: number) => {
    const now = Date.now()
    return start <= now && end >= now
  }

  const isStaffActive = (member: StaffMember) => {
    return !member.freeze && !member.reduce
  }

  const filteredStaff = useMemo(() => {
    return allStaff.filter(
      (member) =>
        (member.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.userId?.includes(searchTerm)) &&
        (statusFilter === "all" ||
          (statusFilter === "active" && isStaffActive(member)) ||
          (statusFilter === "inactive" && !isStaffActive(member)))
    )
  }, [allStaff, searchTerm, statusFilter])

  const staffWithAusencia = useMemo(
    () =>
      filteredStaff.filter(
        (member) =>
          member.freeze && isDateValid(member.startTime!, member.endTime!)
      ),
    [filteredStaff]
  )

  const staffWithReduccion = useMemo(
    () =>
      filteredStaff.filter(
        (member) =>
          member.reduce && isDateValid(member.startReduce!, member.endReduce!)
      ),
    [filteredStaff]
  )

  const staffWithExpiredAusencia = useMemo(
    () =>
      filteredStaff.filter(
        (member) =>
          member.freeze && !isDateValid(member.startTime!, member.endTime!)
      ),
    [filteredStaff]
  )

  const formatDate = (timestamp: number | undefined) => {
    if (!timestamp) return "Fecha no disponible"
    return new Date(timestamp).toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  const getDiscordMessageLink = (messageInfo: string | undefined) => {
    if (!messageInfo) return "#"
    const [channelId, messageId] = messageInfo.split("_")
    return `https://discord.com/channels/596140141668597783/${channelId}/${messageId}`
  }

  const StaffMemberCard = ({ member }: { member: StaffMember }) => {
    const isActive = isStaffActive(member)
    return (
      <MotionBox
        borderWidth="1px"
        borderRadius="lg"
        p={4}
        bg={cardBgColor}
        boxShadow="md"
        transition={{ duration: 0.3 }}
        _hover={{ transform: "translateY(-4px)", boxShadow: "lg" }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        <VStack align="stretch" spacing={3}>
          <Flex justify="space-between" align="center">
            <Heading size="md" isTruncated>
              {member.username}
            </Heading>
            <Badge colorScheme={member.freeze ? "red" : member.reduce ? "yellow" : "green"}>
              {member.freeze ? "Ausencia" : member.reduce ? "Reducción" : "Activo"}
            </Badge>
          </Flex>
          <Text fontSize="sm" color="gray.500">
            <Icon as={FiUser} mr={2} />
            ID: {member.userId}
          </Text>
          {member.freeze && (
            <Text fontSize="sm" color="gray.500">
              <Icon as={FiCalendar} mr={2} />
              Ausencia: {formatDate(member.startTime)} - {formatDate(member.endTime)}
            </Text>
          )}
          {member.reduce && (
            <Text fontSize="sm" color="gray.500">
              <Icon as={FiClock} mr={2} />
              Reducción: {formatDate(member.startReduce)} - {formatDate(member.endReduce)}
            </Text>
          )}
          {!isActive && (
            <Text fontSize="sm" color="gray.500">
              <Icon as={FiCalendar} mr={2} />
              Duración: {member.freeze ? member.days : member.reduceDays} días
            </Text>
          )}
          {!isActive && (member.ausenciaMessage || member.reduceMessage) && (
            <Button
              leftIcon={<FiEye />}
              size="sm"
              variant="outline"
              colorScheme="orange"
              as="a"
              href={getDiscordMessageLink(member.ausenciaMessage?.[0] || member.reduceMessage?.[0])}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver mensaje en Discord
            </Button>
          )}
        </VStack>
      </MotionBox>
    )
  }

  const StaffList = ({ staffList }: { staffList: StaffMember[] }) => {
    const [displayedStaff, setDisplayedStaff] = useState<StaffMember[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10

    useEffect(() => {
      setDisplayedStaff(staffList.slice(0, itemsPerPage))
      setCurrentPage(1)
    }, [staffList])

    const loadMore = () => {
      const nextPage = currentPage + 1
      const startIndex = currentPage * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      const newStaff = staffList.slice(startIndex, endIndex)
      setDisplayedStaff(prevStaff => [...prevStaff, ...newStaff])
      setCurrentPage(nextPage)
    }

    return (
      <VStack spacing={6} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {displayedStaff.map((member) => (
            <StaffMemberCard key={member._id} member={member} />
          ))}
        </SimpleGrid>
        {displayedStaff.length < staffList.length && (
          <Flex justify="center" mt={4}>
            <Button
              onClick={loadMore}
              colorScheme="orange"
              leftIcon={<FiChevronDown />}
            >
              Cargar más
            </Button>
          </Flex>
        )}
      </VStack>
    )
  }

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" color={accentColor} thickness="4px" />
      </Flex>
    )
  }

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Text color="red.500" fontSize="xl" textAlign="center">
          {error}
        </Text>
      </Flex>
    )
  }

  return (
    <Box bg={bgColor} minHeight="100vh" py={8}>
      <Container maxW="container.xl">
        <Heading as="h1" size="2xl" mb={8} textAlign="center" color={accentColor}>
          Administración de Personal
        </Heading>
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          mb={8}
          gap={4}
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FiSearch} color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Buscar por nombre o Discord ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              maxWidth={{ base: "100%", md: "300px" }}
            />
          </InputGroup>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            maxWidth={{ base: "100%", md: "200px" }}
          >
            <option value="all">Todos</option>
            <option value="active">Activos</option>
            <option value="inactive">Inactivos</option>
          </Select>
        </Flex>
        <Tabs variant="soft-rounded" colorScheme="orange" isLazy>
          <TabList mb={4} overflowX="auto" flexWrap="nowrap">
            <Tab>Todo el personal ({filteredStaff.length})</Tab>
            <Tab>Ausencia ({staffWithAusencia.length})</Tab>
            <Tab>Reducción ({staffWithReduccion.length})</Tab>
            <Tab>Ausencias Expiradas ({staffWithExpiredAusencia.length})</Tab>
          </TabList>
          <TabPanels>
            <TabPanel><StaffList staffList={filteredStaff} /></TabPanel>
            <TabPanel><StaffList staffList={staffWithAusencia} /></TabPanel>
            <TabPanel><StaffList staffList={staffWithReduccion} /></TabPanel>
            <TabPanel><StaffList staffList={staffWithExpiredAusencia} /></TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  )
}

export default AdminStaff