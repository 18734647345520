import axios, { AxiosRequestConfig } from 'axios';

export interface ApiResponse<T> {
  json(): unknown;
  ok: boolean;
  status: number;
  success: boolean;
  data?: T;
  error?: string;
}

const BASE_URL = process.env.REACT_APP_API_URL;

const token = process.env.REACT_APP_API_TOKEN; 

const request = async <T>(
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
  body?: any,
  config: AxiosRequestConfig = {}
): Promise<ApiResponse<T>> => {
  try {
    const response = await axios({
      url: `${BASE_URL}${endpoint}`,
      method,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      ...config,
    });

    return { 
      success: true, 
      data: response.data, 
      json: () => response.data, 
      ok: response.status >= 200 && response.status < 300, 
      status: response.status 
    };
  } catch (error: any) {
    console.error('Error en la solicitud API:', error);
    const errorMessage = error.response?.data || error.message;
    return { 
      success: false, 
      error: typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage),
      json: () => null,
      ok: false,
      status: error.response?.status || 500 
    };
  }
};

export const get = async <T>(endpoint: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
  return request<T>(endpoint, 'GET', undefined, config);
};

export const post = async <T>(endpoint: string, body: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
  return request<T>(endpoint, 'POST', body, config);
};

export const put = async <T>(endpoint: string, body: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
  return request<T>(endpoint, 'PUT', body, config);
};

export const del = async <T>(endpoint: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
  return request<T>(endpoint, 'DELETE', undefined, config);
};