import { useRef, useEffect, useState, forwardRef } from 'react';
import { Box, BoxProps, Container, Heading, Spinner, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { MotionProps, motion, isValidMotionProp } from 'framer-motion';

interface DynamicHtmlContentProps {
  htmlContent: string;
  isLoading?: boolean;
}

type MotionBoxProps = Omit<MotionProps, keyof BoxProps> & BoxProps & {
    as?: React.ElementType;
  };
  
  const MotionBox = motion(
    forwardRef<BoxProps, 'div'>((props, ref) => {
      const chakraProps = Object.fromEntries(
        Object.entries(props).filter(([key]) => !isValidMotionProp(key))
      );
      return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
    }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
  );

const DynamicHtmlContent: React.FC<DynamicHtmlContentProps> = ({ 
  htmlContent, 
  isLoading = false 
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isClient, setIsClient] = useState(false);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('orange.200', 'orange.700');
  const headingColor = useColorModeValue('orange.600', 'orange.300');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient && containerRef.current) {
      const container = containerRef.current;
      
      const iframe = document.createElement('iframe');
      iframe.style.width = '100%';
      iframe.style.height = '800px';
      iframe.style.border = 'none';
      
      container.innerHTML = '';
      container.appendChild(iframe);
      
      const doc = iframe.contentDocument || iframe.contentWindow?.document;
      if (doc) {
        doc.open();
        doc.write(htmlContent);
        doc.close();
        
        const style = doc.createElement('style');
        style.textContent = `
          /* Firefox */
          * {
            scrollbar-width: thin;
            scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.05);
          }
          
          /* Chrome, Edge, and Safari */
          *::-webkit-scrollbar {
            width: 10px;
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.05);
          }
          
          *::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 8px;
          }
          
          *::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 8px;
          }
          
          /* Ensure the content is properly styled */
          body {
            margin: 0;
            padding: 16px;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          }
        `;
        doc.head.appendChild(style);
      }
    }
  }, [htmlContent, isClient]);

  if (isLoading) {
    return (
        <Container maxW="container.xl" py={8}>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          bg={bgColor}
          borderRadius="xl"
          overflow="hidden"
          boxShadow="xl"
          border="1px"
          borderColor={borderColor}
          p={8}
        >
          <VStack spacing={6} align="center">
            <Spinner size="xl" color="orange.500" thickness="4px" speed="0.65s" />
            <Heading as="h2" size="xl" textAlign="center" color={headingColor}>
              Cargando datos...
            </Heading>
            <Text fontSize="lg" textAlign="center" color={textColor}>
              Por favor, espere mientras verificamos su acceso.
            </Text>
          </VStack>
        </MotionBox>
      </Container>
    );
  }

  return (
    <Box
      ref={containerRef}
      width="full"
      minHeight={{ base: "400px", md: "600px", lg: "800px" }}
      borderRadius="md"
      overflow="hidden"
      sx={{
        iframe: {
          border: 'none',
          borderRadius: 'md',
        }
      }}
    />
  );
};

export default DynamicHtmlContent;