import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Text,
  Spinner,
  Button,
  Flex,
  useColorModeValue,
  VStack,
  HStack,
  useBreakpointValue,
  Input,
  IconButton,
  Tooltip,
  Link,
} from '@chakra-ui/react';
import { FiSearch, FiExternalLink, FiCopy, FiChevronDown } from 'react-icons/fi';
import { get } from '../../utils/apiUtils';

interface Retire {
  _id: string;
  nick: string;
  date: string;
  url: string;
  uuid: string;
}

const ITEMS_PER_PAGE = 20;

const AdminRetires: React.FC = () => {
  const [retires, setRetires] = useState<Retire[]>([]);
  const [displayedRetires, setDisplayedRetires] = useState<Retire[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const isMobile = useBreakpointValue({ base: true, md: false });

  const fetchRetires = useCallback(async (pageNum: number) => {
    setIsLoading(true);
    try {
      const respone = await get<Retire[]>(`retires?page=${pageNum}&limit=${ITEMS_PER_PAGE}`);
      if (!respone.ok) {
        throw new Error('Failed to fetch retires');
      }
      const data = await respone.json() as Retire[];
      if (data.length < ITEMS_PER_PAGE) {
        setHasMore(false);
      }
      return data;
    } catch (error) {
      console.error('Error fetching retires:', error);
      setError('Error al cargar los retires. Por favor, intenta de nuevo más tarde.');
      return [];
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const loadInitialRetires = async () => {
      const initialRetires = await fetchRetires(1);
      setRetires(initialRetires);
      setDisplayedRetires(initialRetires);
    };
    loadInitialRetires();
  }, [fetchRetires]);

  const loadMore = async () => {
    const nextPage = page + 1;
    const newRetires = await fetchRetires(nextPage);
    setRetires(prevRetires => [...prevRetires, ...newRetires]);
    setDisplayedRetires(prevDisplayed => [...prevDisplayed, ...newRetires]);
    setPage(nextPage);
  };

  const handleSearch = async () => {
    if (searchTerm) {
      setIsLoading(true);
      try {
        const response = await fetch(`http://localhost:8080/api/retires/${searchTerm}`);
        if (!response.ok) {
          throw new Error('Retires not found');
        }
        const data = await response.json();
        setRetires(data);
        setDisplayedRetires(data);
        setHasMore(false);
      } catch (error) {
        console.error('Error searching retires:', error);
        setError('No se encontraron retires para ese nick.');
      } finally {
        setIsLoading(false);
      }
    } else {
      // If search term is empty, reset to initial state
      const initialRetires = await fetchRetires(1);
      setRetires(initialRetires);
      setDisplayedRetires(initialRetires);
      setPage(1);
      setHasMore(true);
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
      <Box bg={bgColor} borderRadius="lg" boxShadow="md" p={4}>
        <Heading as="h2" size="lg" mb={6} color={textColor}>
          Administración de Retires
        </Heading>
        <Flex mb={6} direction={isMobile ? "column" : "row"} gap={4}>
          <Input
              placeholder="Buscar por nick"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              width={isMobile ? "full" : "200px"}
          />
          <Button
              onClick={handleSearch}
              colorScheme="orange"
              width={isMobile ? "full" : "auto"}
              leftIcon={<FiSearch />}
          >
            Buscar
          </Button>
        </Flex>
        {isLoading && displayedRetires.length === 0 ? (
            <Flex justifyContent="center" mt={4}>
              <Spinner size="xl" color="orange.500" />
            </Flex>
        ) : error ? (
            <Text color="red.500">{error}</Text>
        ) : displayedRetires.length === 0 ? (
            <Text>No se encontraron retires.</Text>
        ) : (
            <>
              {isMobile ? (
                  <VStack spacing={4} align="stretch">
                    {displayedRetires.map((retire) => (
                        <Box key={retire._id} p={4} borderWidth={1} borderRadius="md" borderColor={borderColor}>
                          <VStack align="start" spacing={2}>
                            <Text fontWeight="bold">Nick: {retire.nick}</Text>
                            <HStack>
                              <Text>UUID: {truncateText(retire.uuid, 8)}</Text>
                              <Tooltip label="Copiar UUID completo">
                                <IconButton
                                    aria-label="Copiar UUID"
                                    icon={<FiCopy />}
                                    size="sm"
                                    onClick={() => copyToClipboard(retire.uuid)}
                                />
                              </Tooltip>
                            </HStack>
                            <HStack>
                              <Text>URL: {truncateText(retire.url, 20)}</Text>
                              <Tooltip label="Abrir URL">
                                <IconButton
                                    as={Link}
                                    href={retire.url}
                                    isExternal
                                    aria-label="Abrir URL"
                                    icon={<FiExternalLink />}
                                    size="sm"
                                />
                              </Tooltip>
                            </HStack>
                            <Text>Fecha: {new Date(retire.date).toLocaleString()}</Text>
                          </VStack>
                        </Box>
                    ))}
                  </VStack>
              ) : (
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Nick</Th>
                          <Th>UUID</Th>
                          <Th>URL</Th>
                          <Th>Fecha</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {displayedRetires.map((retire) => (
                            <Tr key={retire._id}>
                              <Td>{retire.nick}</Td>
                              <Td>
                                <HStack>
                                  <Text>{truncateText(retire.uuid, 8)}</Text>
                                  <Tooltip label="Copiar UUID completo">
                                    <IconButton
                                        aria-label="Copiar UUID"
                                        icon={<FiCopy />}
                                        size="sm"
                                        onClick={() => copyToClipboard(retire.uuid)}
                                    />
                                  </Tooltip>
                                </HStack>
                              </Td>
                              <Td>
                                <HStack>
                                  <Text>{truncateText(retire.url, 20)}</Text>
                                  <Tooltip label="Abrir URL">
                                    <IconButton
                                        as={Link}
                                        href={retire.url}
                                        isExternal
                                        aria-label="Abrir URL"
                                        icon={<FiExternalLink />}
                                        size="sm"
                                    />
                                  </Tooltip>
                                </HStack>
                              </Td>
                              <Td>{new Date(retire.date).toLocaleString()}</Td>
                            </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
              )}
              {hasMore && (
                  <Flex justifyContent="center" mt={6}>
                    <Button
                        onClick={loadMore}
                        colorScheme="orange"
                        isLoading={isLoading}
                        loadingText="Cargando..."
                        leftIcon={<FiChevronDown />}
                    >
                      Cargar más
                    </Button>
                  </Flex>
              )}
            </>
        )}
      </Box>
  );
};

export default AdminRetires;