import React, { useState, useEffect, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Spinner,
  useColorModeValue,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Tooltip,
  useToast,
  useBreakpointValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Link,
  Heading,
  BoxProps
} from '@chakra-ui/react';
import { FiSearch, FiRefreshCw, FiChevronDown, FiPlus, FiTrash2, FiClock, FiHash, FiKey, FiCopy, FiExternalLink } from 'react-icons/fi';
import { get, post, del } from '../../utils/apiUtils';
import { isValidMotionProp, motion, MotionProps } from 'framer-motion';

type MotionBoxProps = Omit<MotionProps, keyof BoxProps> & BoxProps & {
    as?: React.ElementType;
  };
  
  const MotionBox = motion(
    forwardRef<BoxProps, 'div'>((props, ref) => {
      const chakraProps = Object.fromEntries(
        Object.entries(props).filter(([key]) => !isValidMotionProp(key))
      );
      return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
    }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
  );
  
interface BypassToken {
  _id: string;
  bypass_key: string;
  filename: string;
  bypass_key_expiration: string;
  bypass_uses_left: number;
}

const AdminTokenManagement: React.FC = () => {
  const [tokens, setTokens] = useState<BypassToken[]>([]);
  const [displayedTokens, setDisplayedTokens] = useState<BypassToken[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [isCreating, setIsCreating] = useState(false);
  const [filename, setFilename] = useState('');
  const [expirationTime, setExpirationTime] = useState<number>(24);
  const [maxUses, setMaxUses] = useState<number>(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tokenToDelete, setTokenToDelete] = useState<string | null>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const accentColor = useColorModeValue('brand.500', 'brand.300');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
  const inputBgColor = useColorModeValue('white', 'gray.700');
  const tableHeaderBgColor = useColorModeValue('gray.50', 'gray.900');

  const isMobile = useBreakpointValue({ base: true, md: false });

  const itemsPerPage = 10;

  useEffect(() => {
    fetchTokens();
  }, []);

  const fetchTokens = async () => {
    try {
      setIsLoading(true);
      const response = await get<BypassToken[]>('transcripts/bypass');
      if (response.success) {
        setTokens(response.data || []);
        applyFilters(response.data || []);
      } else {
        throw new Error(response.error || 'Failed to fetch tokens');
      }
    } catch (error) {
      setError('Error al cargar los tokens. Por favor, intenta de nuevo.');
      console.error('Error fetching tokens:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = (tokensToFilter: BypassToken[]) => {
    let filtered = tokensToFilter.filter(
      (token) =>
        token.filename.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.bypass_key.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setDisplayedTokens(filtered.slice(0, itemsPerPage));
    setPage(1);
  };

  useEffect(() => {
    applyFilters(tokens);
  }, [searchTerm, tokens]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setDisplayedTokens(tokens.slice(0, nextPage * itemsPerPage));
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Fecha no válida';
    }
    return date.toLocaleString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC'
    });
  };

  const generateRandomHex = (length: number): string => {
    let result = '';
    const characters = '0123456789ABCDEF';

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const generateBypassKey = (): string => {
    const randomPart = generateRandomHex(16);
    const randomNumbers = Math.floor(1000000 + Math.random() * 9000000).toString();
    const encryptedCode = 'M1N314T1N0';

    const placeEncryptedCodeAtStart = Math.random() < 0.5;

    return placeEncryptedCodeAtStart
      ? `${encryptedCode}${randomPart}${randomNumbers}`
      : `${randomPart}${encryptedCode}${randomNumbers}`;
  };

  const handleCreateToken = async () => {
    const generatedKey = generateBypassKey();
    try {
      setIsCreating(true);
      if (!filename) {
        toast({
          title: 'Error',
          description: 'Por favor, ingresa un nombre de archivo.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const expirationDate = new Date();
      expirationDate.setHours(expirationDate.getHours() + expirationTime);

      const response = await post(`transcript/${filename}/bypass`, {
        bypass_key: generatedKey,
        expiration_time_in_hours: expirationTime,
        max_uses: maxUses,
      });

      if (response.success) {
        toast({
          title: 'Token creado',
          description: `Se ha creado un nuevo token para ${filename}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchTokens();
        setFilename('');
        setExpirationTime(24);
        setMaxUses(1);
      } else {
        throw new Error(response.error || 'Error al crear el token');
      }
    } catch (error) {
      let errorMessage = 'No se pudo crear el token. Por favor, intenta de nuevo.';
      if (error instanceof Error) {
        if (error.message.includes('Transcript not found')) {
          errorMessage = `El archivo "${filename}" no existe. Por favor, verifica el nombre del archivo.`;
        } else if (error.message.includes('Ya existe una clave de bypass')) {
          errorMessage = `Ya existe una clave de bypass para el archivo "${filename}".`;
        }
      }
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleDeleteToken = async () => {
    if (!tokenToDelete) return;

    try {
      const response = await del(`transcript/${tokenToDelete}/bypass`);
      if (response.success) {
        toast({
          title: 'Token eliminado',
          description: `Se ha eliminado el token para ${tokenToDelete}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchTokens();
      } else {
        throw new Error(response.error || 'Error al eliminar el token');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'No se pudo eliminar el token. Por favor, intenta de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setTokenToDelete(null);
      onClose();
    }
  };

  const openDeleteDialog = (filename: string) => {
    setTokenToDelete(filename);
    onOpen();
  };

  const TokenCard = ({ token }: { token: BypassToken }) => (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      mb={4}
      bg={bgColor}
      boxShadow="sm"
      _hover={{ boxShadow: "md" }}
    >
      <VStack align="stretch" spacing={2}>
        <HStack justify="space-between">
          <Text fontWeight="bold" fontSize="sm">{token.filename}</Text>
          <Badge colorScheme={new Date(token.bypass_key_expiration) > new Date() && token.bypass_uses_left > 0 ? "green" : "red"} fontSize="xs">
            {new Date(token.bypass_key_expiration) > new Date() && token.bypass_uses_left > 0 ? "Activo" : "Inactivo"}
          </Badge>
        </HStack>
        <HStack justify="space-between" fontSize="xs">
          <Text>Token: {token.bypass_key.substring(0, 8)}...</Text>
          <Text>Usos restantes: {token.bypass_uses_left}</Text>
        </HStack>
        <HStack justify="space-between" fontSize="xs">
          <Text>Expira: {formatDate(token.bypass_key_expiration)}</Text>
          <RouterLink to={`/tickets-logs/${token.filename}`}>
            <Link color={accentColor}>
              Ver transcript <Icon as={FiExternalLink} ml={1} />
            </Link>
          </RouterLink>
        </HStack>
        <Button
          leftIcon={<FiTrash2 />}
          colorScheme="red"
          size="sm"
          onClick={() => openDeleteDialog(token.filename)}
        >
          Eliminar
        </Button>
      </VStack>
    </Box>
  );

  const hasMore = page * itemsPerPage < tokens.length;

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={8} align="stretch">
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box bg={bgColor} borderRadius="xl" p={8} boxShadow="lg" borderWidth="1px" borderColor={borderColor}>
            <VStack spacing={6}>
              <Flex width="100%" gap={4} direction={{ base: 'column', md: 'row' }}>
                <InputGroup size="lg" flex={1}>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FiSearch} color="gray.400" />
                  </InputLeftElement>
                  <Input
                    placeholder="Buscar tokens..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    bg={inputBgColor}
                    _focus={{ borderColor: "brand.400", boxShadow: "0 0 0 1px brand.400" }}
                  />
                </InputGroup>
                <Button
                  leftIcon={<FiRefreshCw />}
                  onClick={fetchTokens}
                  colorScheme="orange"
                  
                  variant="outline"
                  _hover={{ bg: 'orange.50' }}
                  size="lg"
                  width={{ base: '100%', md: 'auto' }}
                >
                  Actualizar
                </Button>
              </Flex>
            </VStack>
          </Box>
        </MotionBox>

        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Box bg={bgColor} borderRadius="xl" p={8} boxShadow="lg" borderWidth="1px" borderColor={borderColor}>
            <VStack spacing={6} align="stretch">
              <Heading as="h3" size="lg" fontWeight="bold" color={textColor}>Crear Nuevo Token</Heading>
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                <FormControl>
                  <FormLabel fontWeight="medium">Nombre del archivo</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiKey} color="gray.300" />
                    </InputLeftElement>
                    <Input
                      value={filename}
                      onChange={(e) => setFilename(e.target.value)}
                      placeholder="Ingresa el nombre del archivo"
                      _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="medium">Tiempo de expiración (horas)</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiClock} color="gray.300" />
                    </InputLeftElement>
                    <NumberInput
                      min={1}
                      max={72}
                      value={expirationTime}
                      onChange={(valueString) => setExpirationTime(Number(valueString))}
                    >
                      <NumberInputField pl={10} _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }} />
                      <NumberInputStepper>
                        
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="medium">Usos máximos</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiHash} color="gray.300" />
                    </InputLeftElement>
                    <NumberInput
                      min={1}
                      value={maxUses}
                      onChange={(valueString) => setMaxUses(Number(valueString))}
                    >
                      <NumberInputField pl={10} _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }} />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </InputGroup>
                </FormControl>
              </SimpleGrid>
              <Button
                leftIcon={<FiPlus />}
                colorScheme="green"
                onClick={handleCreateToken}
                isLoading={isCreating}
                loadingText="Creando..."
                size="lg"
                fontWeight="bold"
                variant="outline"
                _hover={{ bg: 'orange.50' }}
                transition="all 0.2s"
              >
                Crear Token
              </Button>
            </VStack>
          </Box>
        </MotionBox>

        {isLoading ? (
          <Flex justify="center" align="center" minHeight="200px">
            <Spinner size="xl" color="brand.500" thickness="4px" />
          </Flex>
        ) : displayedTokens.length > 0 ? (
          <>
            {isMobile ? (
              <SimpleGrid columns={1} spacing={4}>
                {displayedTokens.map((token) => (
                  <TokenCard key={token._id} token={token} />
                ))}
              </SimpleGrid>
            ) : (
              <Box overflowX="auto" borderRadius="lg" borderWidth="1px" borderColor={borderColor}>
                <Table variant="simple">
                  <Thead bg={tableHeaderBgColor}>
                    <Tr>
                      <Th color={accentColor}>Archivo</Th>
                      <Th color={accentColor}>Token</Th>
                      <Th color={accentColor}>Expira</Th>
                      <Th color={accentColor}>Usos restantes</Th>
                      <Th color={accentColor}>Estado</Th>
                      <Th color={accentColor}>Acción</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {displayedTokens.map((token) => (
                      <Tr key={token._id} _hover={{ bg: hoverBgColor }}>
                        <Td>
                          <RouterLink to={`/tickets-logs/${token.filename}`}>
                            <Link color={accentColor}>
                              {token.filename} <Icon as={FiExternalLink} ml={1} />
                            </Link>
                          </RouterLink>
                        </Td>
                        <Td>
                          <Tooltip label={token.bypass_key}>
                            <HStack spacing={2}>
                              <Text>{token.bypass_key.substring(0, 8)}...</Text>
                              <Icon
                                as={FiCopy}
                                cursor="pointer"
                                onClick={() => {
                                  navigator.clipboard.writeText(token.bypass_key);
                                  toast({
                                    title: "Token copiado",
                                    status: "success",
                                    duration: 2000,
                                    isClosable: true,
                                  });
                                }}
                              />
                            </HStack>
                          </Tooltip>
                        </Td>
                        <Td>{formatDate(token.bypass_key_expiration)}</Td>
                        <Td>{token.bypass_uses_left}</Td>
                        <Td>
                          <Badge colorScheme={new Date(token.bypass_key_expiration) > new Date() && token.bypass_uses_left > 0 ? "green" : "red"}>
                            {new Date(token.bypass_key_expiration) > new Date() && token.bypass_uses_left > 0 ? "Activo" : "Inactivo"}
                          </Badge>
                        </Td>
                        <Td>
                          <Button
                            leftIcon={<FiTrash2 />}
                            colorScheme="red"
                            size="sm"
                            
                  variant="outline"
                  _hover={{ bg: 'orange.50' }}
                            onClick={() => openDeleteDialog(token.filename)}
                          >
                            Eliminar
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
            {hasMore && (
              <Flex justifyContent="center" mt={6}>
                <Button
                  onClick={loadMore}
                  colorScheme="brand"
                  size="lg"
                  leftIcon={<FiChevronDown />}
                  _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                  transition="all 0.2s"
                  borderRadius="full"
                  px={8}
                  py={6}
                  fontWeight="bold"
                  fontSize="md"
                  bgGradient="linear(to-r, brand.400, brand.600)"
                  _active={{ bgGradient: "linear(to-r, brand.500, brand.700)" }}
                >
                  Cargar más tokens
                </Button>
              </Flex>
            )}
          </>
        ) : (
          <Box textAlign="center" py={10}>
            <Text fontSize="xl" color={textColor}>
              No se encontraron tokens.
            </Text>
          </Box>
        )}
      </VStack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Eliminar Token
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro? No podrás deshacer esta acción después.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDeleteToken} ml={3}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default AdminTokenManagement;