import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../auth/supabaseClient';
import { Box, VStack, Heading, Text, Spinner, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const accentColor = useColorModeValue('orange.500', 'orange.300');

  useEffect(() => {
    const handleAuthCallback = async () => {
      const { error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error during auth callback:', error);
        navigate('/');
      } else {
        const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
        localStorage.removeItem('redirectAfterLogin');
        navigate(redirectTo);
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={bgColor}
    >
      <VStack spacing={8}>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={accentColor}
            size="xl"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <VStack>
            <Heading color={textColor} fontSize="2xl" textAlign="center">
              Procesando inicio de sesión
            </Heading>
            <Text color={textColor} fontSize="lg" textAlign="center">
              Preparando tu aventura en MineLatino...
            </Text>
          </VStack>
        </motion.div>
      </VStack>
    </Box>
  );
};

export default AuthCallback;