import React, { useState } from "react"
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  useColorModeValue,
  useToast,
  Badge,
  Stack,
  useBreakpointValue,
  Avatar,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react"
import { SearchIcon, UserCheck, UserX, Users } from "lucide-react"
import { get } from "../../utils/apiUtils"
interface LinkedUser {
  id: string
  discordUsername: string
  mongoNick: string | null
  avatar: string
}

export default function AdminLinkedUsers() {
  const [linkedUsers, setLinkedUsers] = useState<LinkedUser[]>([])
  const [unlinkedUsers, setUnlinkedUsers] = useState<LinkedUser[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [filterType, setFilterType] = useState("all")
  const toast = useToast()

  const bgColor = useColorModeValue("white", "gray.800")
  const borderColor = useColorModeValue("gray.200", "gray.700")
  const textColor = useColorModeValue("gray.800", "gray.100")
  const accentColor = useColorModeValue("blue.500", "blue.300")

  const isMobile = useBreakpointValue({ base: true, md: false })

  const fetchLinkedUsers = async () => {
    setIsLoading(true)
    try {
      const response = await get<{ linked: LinkedUser[]; unlinked: LinkedUser[] }>(
        "linked-users/597496371729268746"
      )
      if (response.success) {
        if (response.data) {
          setLinkedUsers(response.data.linked)
          setUnlinkedUsers(response.data.unlinked)
        } else {
          throw new Error("Response data is undefined")
        }
        toast({
          title: "Datos cargados exitosamente",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } else {
        throw new Error(response.error || "Error al cargar los datos")
      }
    } catch (error) {
      console.error("Error fetching linked users:", error)
      toast({
        title: "Error al cargar los datos",
        description: "Por favor, intenta de nuevo más tarde.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }
  

  const filteredUsers = [...linkedUsers, ...unlinkedUsers].filter((user) => {
    if (filterType === "linked") return linkedUsers.includes(user)
    if (filterType === "unlinked") return unlinkedUsers.includes(user)
    return true
  }).filter((user) =>
    user.discordUsername.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (user.mongoNick && user.mongoNick.toLowerCase().includes(searchTerm.toLowerCase()))
  )

  return (
    <Box bg={bgColor} p={4} borderRadius="lg" boxShadow="lg" borderWidth="1px" borderColor={borderColor}>
      <VStack spacing={6} align="stretch">
        <Heading as="h2" size="lg" color={textColor} textAlign="center">
          Usuarios Vinculados
        </Heading>

        <Alert status="warning" borderRadius="md" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" p={4}>
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Advertencia
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Este botón solo debe usarse para ver los datos. No lo uses repetidamente debido a los límites de tasa. 
            El escaneo puede tardar hasta 2 minutos en completarse.
          </AlertDescription>
        </Alert>

        <Button
          colorScheme="blue"
          onClick={fetchLinkedUsers}
          isLoading={isLoading}
          loadingText="Cargando datos..."
          leftIcon={<SearchIcon />}
          size="lg"
          width="full"
          _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
          transition="all 0.2s"
        >
          Mostrar Usuarios Vinculados
        </Button>

        <Stack direction={{ base: "column", md: "row" }} spacing={4}>
          <Input
            placeholder="Buscar por nombre de usuario"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="lg"
          />
          <Select value={filterType} onChange={(e) => setFilterType(e.target.value)} size="lg">
            <option value="all">Todos</option>
            <option value="linked">Vinculados</option>
            <option value="unlinked">No vinculados</option>
          </Select>
        </Stack>

        {isLoading ? (
          <Flex justify="center" align="center" minHeight="200px">
            <Spinner size="xl" color={accentColor} thickness="4px" />
          </Flex>
        ) : (
          <>
            {isMobile ? (
              <Accordion allowMultiple>
                {filteredUsers.map((user) => (
                  <AccordionItem key={user.id}>
                    <h2>
                      <AccordionButton>
                        <Flex flex="1" textAlign="left" alignItems="center" justifyContent="space-between">
                          <Flex align="center">
                            <Avatar src={user.avatar} name={user.discordUsername} size="sm" mr={2} />
                            <Text fontWeight="bold" isTruncated>{user.discordUsername}</Text>
                          </Flex>
                          {linkedUsers.includes(user) ? (
                            <UserCheck size={20} color="green" />
                          ) : (
                            <UserX size={20} color="red" />
                          )}
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <VStack align="start" spacing={2}>
                        <Text><strong>Nombre completo:</strong> {user.discordUsername}</Text>
                        <Text><strong>Nombre en MC:</strong> {user.mongoNick || "N/A"}</Text>
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            ) : (
              <Box overflowX="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Usuario de Discord</Th>
                      <Th>Nombre en MC</Th>
                      <Th>Estado</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredUsers.map((user) => (
                      <Tr key={user.id}>
                        <Td>
                          <Flex align="center">
                            <Avatar src={user.avatar} name={user.discordUsername} size="sm" mr={2} />
                            <Text fontWeight="bold">{user.discordUsername}</Text>
                          </Flex>
                        </Td>
                        <Td>{user.mongoNick || "N/A"}</Td>
                        <Td>
                          <Badge
                            colorScheme={linkedUsers.includes(user) ? "green" : "red"}
                            display="flex"
                            alignItems="center"
                            px={2}
                            py={1}
                            borderRadius="full"
                          >
                            {linkedUsers.includes(user) ? (
                              <>
                                <UserCheck size={16} style={{ marginRight: '4px' }} />
                                Vinculado
                              </>
                            ) : (
                              <>
                                <UserX size={16} style={{ marginRight: '4px' }} />
                                No vinculado
                              </>
                            )}
                          </Badge>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </>
        )}

        <Stack direction={{ base: "column", sm: "row" }} justify="center" align="center" spacing={4} wrap="wrap">
          <Badge colorScheme="blue" p={2} borderRadius="md" display="flex" alignItems="center">
            <Users size={16} style={{ marginRight: '4px' }} />
            Total: {filteredUsers.length}
          </Badge>
          <Badge colorScheme="green" p={2} borderRadius="md" display="flex" alignItems="center">
            <UserCheck size={16} style={{ marginRight: '4px' }} />
            Vinculados: {linkedUsers.length}
          </Badge>
          <Badge colorScheme="red" p={2} borderRadius="md" display="flex" alignItems="center">
            <UserX size={16} style={{ marginRight: '4px' }} />
            No vinculados: {unlinkedUsers.length}
          </Badge>
        </Stack>
      </VStack>
    </Box>
  )
}