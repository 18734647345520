import React, { useState, useEffect, forwardRef } from 'react';
import {
  Box,
  Heading,
  Text,
  Spinner,
  useColorModeValue,
  VStack,
  HStack,
  Flex,
  Alert,
  AlertIcon,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  Badge,
  Tooltip,
  Grid,
  GridItem,
  BoxProps,
} from '@chakra-ui/react';
import { isValidMotionProp, motion, MotionProps } from 'framer-motion';
import { FiPhone, FiMessageCircle, FiCheckCircle, FiXCircle, FiClock, FiCalendar } from 'react-icons/fi';
import { get } from '../../utils/apiUtils';

interface UserNumber {
  user_id: string;
  number: string;
  channel: string;
  intents: number;
  verified: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
}

type MotionBoxProps = Omit<MotionProps, keyof BoxProps> & BoxProps & {
  as?: React.ElementType;
};

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);

const UserNumbers: React.FC<{ userId: string | null }> = ({ userId }) => {
  const [numbers, setNumbers] = useState<UserNumber[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const bgColor = useColorModeValue('white', 'gray.800');
  const cardBgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const accentColor = useColorModeValue('orange.500', 'orange.300');

  useEffect(() => {
    const fetchNumbers = async () => {
      if (!userId) {
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        const response = await get<UserNumber[]>(`number/search?user_id=${userId}`);
        if (response.success && response.data) {
          setNumbers(Array.isArray(response.data) ? response.data : [response.data]);
        } else {
          throw new Error(response.error || 'Error al cargar los números');
        }
      } catch (error) {
        console.error('Error fetching user numbers:', error);
        setError('Error al cargar los números. Por favor, intenta de nuevo más tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchNumbers();
  }, [userId]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatTime = (dateString: string) => {
    return new Date(dateString).toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const NumberCard = ({ number }: { number: UserNumber }) => (
    <MotionBox
      bg={cardBgColor}
      p={6}
      borderRadius="lg"
      boxShadow="md"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      width="100%"
    >
      <Grid templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }} gap={6}>
        <GridItem>
          <Stat>
            <StatLabel fontSize="lg" fontWeight="medium">Número</StatLabel>
            <StatNumber fontSize="2xl" fontWeight="bold" color={accentColor}>
              <Icon as={FiPhone} mr={2} />
              {number.number}
            </StatNumber>
          </Stat>
        </GridItem>
        <GridItem>
          <Stat>
            <StatLabel>Intentos restantes</StatLabel>
            <StatNumber>{number.intents}</StatNumber>
          </Stat>
          <VStack align="flex-start" mt={2} spacing={1}>
            <Badge colorScheme={number.verified ? 'green' : 'yellow'} fontSize="sm">
              <Icon as={number.verified ? FiCheckCircle : FiXCircle} mr={1} />
              {number.verified ? 'Verificado' : 'No verificado'}
            </Badge>
            <Badge colorScheme={number.blocked ? 'red' : 'green'} fontSize="sm">
              <Icon as={number.blocked ? FiXCircle : FiCheckCircle} mr={1} />
              {number.blocked ? 'Bloqueado' : 'Activo'}
            </Badge>
          </VStack>
        </GridItem>
        <GridItem>
          <VStack align="flex-start" spacing={2}>
            <Tooltip label={formatTime(number.createdAt)}>
              <HStack>
                <Icon as={FiCalendar} />
                <Text fontSize="sm" color="gray.500">Creado: {formatDate(number.createdAt)}</Text>
              </HStack>
            </Tooltip>
            <Tooltip label={formatTime(number.updatedAt)}>
              <HStack>
                <Icon as={FiClock} />
                <Text fontSize="sm" color="gray.500">Actualizado: {formatDate(number.updatedAt)}</Text>
              </HStack>
            </Tooltip>
          </VStack>
        </GridItem>
      </Grid>
    </MotionBox>
  );

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="50vh">
        <VStack spacing={4}>
          <Spinner size="xl" color={accentColor} thickness="4px" />
          <Text fontSize="lg" fontWeight="medium">Cargando números...</Text>
        </VStack>
      </Flex>
    );
  }

  if (error) {
    return (
      <Alert status="error" borderRadius="md">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  if (numbers.length === 0) {
    return (
      <Alert status="info" borderRadius="md">
        <AlertIcon />
        No tienes números vinculados a tu cuenta.
      </Alert>
    );
  }

  return (
    <Box width="100%" maxWidth="1200px" mx="auto" px={4}>
      <VStack spacing={8} align="stretch" width="100%">
        <VStack spacing={6} width="100%">
          {numbers.map((number) => (
            <NumberCard key={number.number} number={number} />
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};

export default UserNumbers;