import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: any) => void) => {
  if (onPerfEntry && performance.getEntriesByName) {
    performance.getEntriesByName("first-contentful-paint").forEach((entry) => {
      onPerfEntry(entry);
    });
  }
};

export default reportWebVitals;