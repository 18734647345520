import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Icon,
  Spinner,
  useColorModeValue,
  Container,
  SimpleGrid,
  Badge,
  Tooltip,
  useToast,
  BoxProps,
  useBreakpointValue,
  ChakraProvider,
  extendTheme,
  Link,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Collapse,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useClipboard,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { FiFileText, FiUser, FiCalendar, FiFilter, FiRefreshCw, FiChevronDown, FiExternalLink, FiMessageSquare, FiCopy } from 'react-icons/fi';
import { isValidMotionProp, motion, MotionProps } from 'framer-motion';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale/es';
import { get } from '../../utils/apiUtils';

registerLocale('es', es);

type MotionBoxProps = Omit<MotionProps, keyof BoxProps> & BoxProps & {
  as?: React.ElementType;
};

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);

interface Transcript {
  _id: string;
  user_id: string;
  user_name: string;
  closer_id: string;
  closer_name: string;
  channel_id: string;
  filename: string;
  reason: string;
  file_size: number;
  type: string;
  createdAt: string;
  updatedAt: string;
}

const CopyableText: React.FC<{ text: string; maxLength?: number }> = ({ text, maxLength = 20 }) => {
  const { onCopy } = useClipboard(text);
  const toast = useToast();
  const displayText = text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const handleCopy = () => {
    onCopy();
    toast({
      title: "Texto copiado",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <Tooltip label="Haz clic para copiar" placement="top">
      <HStack spacing={1} cursor="pointer" onClick={handleCopy}>
        <Text isTruncated maxW="150px">{displayText}</Text>
        <Icon as={FiCopy} color="gray.500" w={3} h={3} />
      </HStack>
    </Tooltip>
  );
};

const UserTranscripts: React.FC<{ userId: string | null }> = ({ userId }) => {
  const [allTranscripts, setAllTranscripts] = useState<Transcript[]>([]);
  const [displayedTranscripts, setDisplayedTranscripts] = useState<Transcript[]>([]);
  const [filteredTranscripts, setFilteredTranscripts] = useState<Transcript[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedType, setSelectedType] = useState<string>('all');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const accentColor = useColorModeValue('brand.500', 'brand.300');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
  const inputBgColor = useColorModeValue('white', 'gray.700');
  const tableHeaderBgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchTranscripts = async () => {
      if (!userId) return;
      try {
        setIsLoading(true);
        const response = await get<Transcript[]>(`transcripts/search?user_id=${userId}`);
        if (response.success && response.data) {
          setAllTranscripts(response.data);
          applyFilters(response.data);
        } else {
          throw new Error(response.error || 'Failed to fetch transcripts');
        }
      } catch (error) {
        console.error('Error fetching user transcripts:', error);
        setError('Error al cargar los transcripts. Por favor, intenta de nuevo más tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTranscripts();
  }, [userId]);

  const applyFilters = (transcripts: Transcript[]) => {
    let filtered = transcripts;

    if (startDate) {
      filtered = filtered.filter(t => new Date(t.createdAt) >= startDate);
    }
    if (endDate) {
      filtered = filtered.filter(t => new Date(t.createdAt) <= endDate);
    }
    if (selectedType !== 'all') {
      filtered = filtered.filter(t => t.type === selectedType);
    }

    setFilteredTranscripts(filtered);
    setDisplayedTranscripts(filtered.slice(0, itemsPerPage));
    setPage(1);
  };

  useEffect(() => {
    applyFilters(allTranscripts);
  }, [startDate, endDate, selectedType]);

  const hasMore = useMemo(() => page * itemsPerPage < filteredTranscripts.length, [page, filteredTranscripts]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setDisplayedTranscripts(filteredTranscripts.slice(0, nextPage * itemsPerPage));
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const formatTime = (dateString: string) => {
    return new Date(dateString).toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const uniqueTypes = useMemo(() => {
    const types = new Set(allTranscripts.map((t) => t.type));
    return ['all', ...Array.from(types).filter(Boolean)];
  }, [allTranscripts]);

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedType('all');
    applyFilters(allTranscripts);
  };

  const CustomInput = forwardRef<HTMLInputElement, { value?: string; onClick?: () => void; label: string }>(
    ({ value, onClick, label }, ref) => (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FiCalendar} color={accentColor} />
        </InputLeftElement>
        <Input
          ref={ref}
          value={value}
          onClick={onClick}
          readOnly
          placeholder={label}
          bg={inputBgColor}
          _hover={{ borderColor: accentColor }}
          _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }}
        />
      </InputGroup>
    )
  );

  const TranscriptCard = ({ transcript }: { transcript: Transcript }) => (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      mb={4}
      bg={cardBgColor}
      boxShadow="sm"
      _hover={{ boxShadow: "md" }}
    >
      <Accordion allowToggle>
        <AccordionItem border="none">
          <AccordionButton p={0} _hover={{ bg: 'transparent' }}>
            <Box flex="1" textAlign="left">
              <HStack justify="space-between">
                <Text fontWeight="bold" fontSize="sm">{transcript.filename}</Text>
                <Badge colorScheme="orange" fontSize="xs" px={2} py={1} borderRadius="full">
                  {transcript.type || 'Sin categoría'}
                </Badge>
              </HStack>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} px={0}>
            <VStack align="stretch" spacing={2}>
              <HStack justify="space-between" fontSize="xs">
                <Text>Usuario: <CopyableText text={transcript.user_name} /></Text>
                <Text>ID: <CopyableText text={transcript.user_id} /></Text>
              </HStack>
              <HStack justify="space-between" fontSize="xs">
                <Text>Cerrado por: <CopyableText text={transcript.closer_name} /></Text>
                <Text>ID: <CopyableText text={transcript.closer_id} /></Text>
              </HStack>
              <HStack justify="space-between" fontSize="xs">
                <Text>Tamaño: {(transcript.file_size / 1024).toFixed(2)} KB</Text>
              </HStack>
              <Text fontSize="xs">Razón: <CopyableText text={transcript.reason || 'N/A'} /></Text>
              <HStack justify="space-between" fontSize="xs">
                <Text>Creado: {formatDate(transcript.createdAt)}</Text>
                <Text>Actualizado: {formatDate(transcript.updatedAt)}</Text>
              </HStack>
              <Link
                href={`/tickets-logs/${transcript.filename}`}
                color={accentColor}
                fontWeight="medium"
                fontSize="sm"
                _hover={{ textDecoration: 'none', color: 'brand.600' }}
              >
                <HStack>
                  <Icon as={FiExternalLink} />
                  <Text>Ver transcript</Text>
                </HStack>
              </Link>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );

  const theme = extendTheme({
    fonts: {
      heading: '"Poppins", sans-serif',
      body: '"Inter", sans-serif',
    },
    colors: {
      brand: {
        50: '#FFF5F0',
        100: '#FFE6D5',
        200: '#FFC7AA',
        300: '#FFA980',
        400: '#FF8A55',
        500: '#FF6B2B',
        600: '#FF4F00',
        700: '#CC3F00',
        800: '#992F00',
        900: '#662000',
      },
    },
    components: {
      Button: {
        baseStyle: {
          fontWeight: 'bold',
          borderRadius: 'md',
        },
        variants: {
          solid: (props: any) => ({
            bg: `${props.colorScheme}.500`,
            color: 'white',
            _hover: {
              bg: `${props.colorScheme}.600`,
            },
          }),
        },
      },
      Input: {
        variants: {
          filled: {
            field: {
              borderRadius: 'md',
              bg: 'gray.100',
              _hover: {
                bg: 'gray.200',
              },
              _focus: {
                bg: 'white',
                borderColor: 'brand.500',
              },
            },
          },
        },
        defaultProps: {
          variant: 'filled',
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box maxW="100%" overflowX="hidden">
        <Container maxW="container.xl" px={4} py={8}>
          <VStack spacing={8} align="stretch">
            <Box bg={bgColor} borderRadius="xl" p={6} boxShadow="lg" borderWidth="1px" borderColor={borderColor}>
              <VStack spacing={6}>
                <Button
                  leftIcon={<FiFilter />}
                  onClick={() => setIsFilterOpen(!isFilterOpen)}
                  colorScheme="brand"
                  variant="outline"
                  width="100%"
                >
                  {isFilterOpen ? "Ocultar filtros" : "Mostrar filtros"}
                </Button>
                <Collapse in={isFilterOpen} animateOpacity>
                  <Flex width="100%" gap={4} direction={{ base: 'column', md: 'row' }}>
                    <Box flex={1}>
                      <DatePicker
                        selected={startDate}
                        onChange={(date: Date | null) => setStartDate(date)}
                        selectsStart
                        startDate={startDate || undefined}
                        endDate={endDate || undefined}
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        customInput={<CustomInput label="Desde" />}
                      />
                    </Box>
                    <Box flex={1}>
                      <DatePicker
                        selected={endDate}
                        onChange={(date: Date | null) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate || undefined}
                        endDate={endDate || undefined} 
                        minDate={startDate || undefined} 
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        customInput={<CustomInput label="Hasta" />}
                      />
                    </Box>
                    <Select
                      value={selectedType}
                      onChange={(e) =>   setSelectedType(e.target.value)}
                      bg={inputBgColor}
                      borderColor={borderColor}
                      _hover={{ borderColor: accentColor }}
                      _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }}
                      size="lg"
                      placeholder="Filtrar por tipo"
                    >
                      {uniqueTypes.map((type) => (
                        <option key={type} value={type}>
                          {type === 'all' ? 'Todos los tipos' : type || 'Sin categoría'}
                        </option>
                      ))}
                    </Select>
                    <Tooltip label="Limpiar filtros" placement="top">
                      <IconButton
                        aria-label="Limpiar filtros"
                        icon={<FiRefreshCw />}
                        onClick={clearFilters}
                        colorScheme="brand"
                        variant="outline"
                        size="lg"
                        isRound
                        _hover={{ bg: "brand.50", transform: 'rotate(180deg)' }}
                        transition="all 0.3s ease-in-out"
                      />
                    </Tooltip>
                  </Flex>
                </Collapse>
              </VStack>
            </Box>

            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {isLoading ? (
              <Flex justify="center" align="center" minHeight="200px">
                <Spinner size="xl" color={accentColor} thickness="4px" />
              </Flex>
            ) : displayedTranscripts.length > 0 ? (
              <>
                {isMobile ? (
                  <SimpleGrid columns={1} spacing={4}>
                    {displayedTranscripts.map((transcript) => (
                      <TranscriptCard key={transcript._id} transcript={transcript} />
                    ))}
                  </SimpleGrid>
                ) : (
                  <Box overflowX="auto" borderRadius="lg" borderWidth="1px" borderColor={borderColor}>
                    <Table variant="simple" size="sm">
                      <Thead bg={tableHeaderBgColor}>
                        <Tr>
                          <Th color={accentColor}>Nombre del archivo</Th>
                          <Th color={accentColor}>Usuario</Th>
                          <Th color={accentColor}>Cerrado por</Th>
                          <Th color={accentColor}>Fecha</Th>
                          <Th color={accentColor}>Tipo</Th>
                          <Th color={accentColor}>Razón</Th>
                          <Th color={accentColor}>Tamaño</Th>
                          <Th color={accentColor}>Acción</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {displayedTranscripts.map((transcript) => (
                          <Tr key={transcript._id} _hover={{ bg: hoverBgColor }}>
                            <Td>
                              <HStack>
                                <Icon as={FiFileText} color={accentColor} />
                                <CopyableText text={transcript.filename} />
                              </HStack>
                            </Td>
                            <Td>
                              <Tooltip label={`ID: ${transcript.user_id}`}>
                                <HStack>
                                  <Icon as={FiUser} color={accentColor} />
                                  <CopyableText text={transcript.user_name} />
                                </HStack>
                              </Tooltip>
                            </Td>
                            <Td>
                              <Tooltip label={`ID: ${transcript.closer_id}`}>
                                <HStack>
                                  <Icon as={FiUser} color={accentColor} />
                                  <CopyableText text={transcript.closer_name} />
                                </HStack>
                              </Tooltip>
                            </Td>
                            <Td>
                              <Tooltip label={formatTime(transcript.createdAt)}>
                                <HStack>
                                  <Icon as={FiCalendar} color={accentColor} />
                                  <Text>{formatDate(transcript.createdAt)}</Text>
                                </HStack>
                              </Tooltip>
                            </Td>
                            <Td>
                              <Badge colorScheme="orange" fontSize="sm" fontWeight="medium" px={2} py={1} borderRadius="full">
                                {transcript.type || 'Sin categoría'}
                              </Badge>
                            </Td>
                            <Td>
                              <Tooltip label={transcript.reason || 'N/A'}>
                                <HStack>
                                  <Icon as={FiMessageSquare} color={accentColor} />
                                  <CopyableText text={transcript.reason || 'N/A'} maxLength={30} />
                                </HStack>
                              </Tooltip>
                            </Td>
                            <Td>{(transcript.file_size / 1024).toFixed(2)} KB</Td>
                            <Td>
                              <Link
                                href={`/tickets-logs/${transcript.filename}`}
                                color={accentColor}
                                fontWeight="medium"
                                _hover={{ textDecoration: 'none', color: 'brand.600' }}
                              >
                                <HStack>
                                  <Icon as={FiExternalLink} />
                                  <Text>Ver</Text>
                                </HStack>
                              </Link>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}
                {hasMore && (
                  <Flex justifyContent="center" mt={6}>
                    <Button
                      onClick={loadMore}
                      colorScheme="brand"
                      size="lg"
                      leftIcon={<FiChevronDown />}
                      _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                      transition="all 0.2s"
                      borderRadius="full"
                      px={8}
                      py={6}
                      fontWeight="bold"
                      fontSize="md"
                      bgGradient="linear(to-r, brand.400, brand.600)"
                      _active={{ bgGradient: "linear(to-r, brand.500, brand.700)" }}
                    >
                      Cargar más transcripts
                    </Button>
                  </Flex>
                )}
              </>
            ) : (
              <Box textAlign="center" py={10}>
                <Text fontSize="xl" color={textColor}>
                  No se encontraron transcripts.
                </Text>
              </Box>
            )}
          </VStack>
        </Container>
      </Box>
    </ChakraProvider>
  );
};

export default UserTranscripts;