import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Button,
  IconButton,
  Spinner,
  useColorModeValue,
  Icon,
  Badge,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Tooltip,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Switch,
  useToast,
  Heading,
  BoxProps,
} from '@chakra-ui/react';
import { isValidMotionProp, motion, MotionProps } from 'framer-motion';
import { FiSearch, FiRefreshCw, FiEdit2, FiTrash2, FiPhone, FiUser, FiMessageCircle, FiRepeat, FiPlus } from 'react-icons/fi';
import { get } from '../../utils/apiUtils';

interface NumberInfo {
  user_id: string;
  number: string;
  channel: string;
  intents: number;
  verified: boolean;
  blocked: boolean;
}

const ITEMS_PER_PAGE = 10;

type MotionBoxProps = Omit<MotionProps, keyof BoxProps> & BoxProps & {
  as?: React.ElementType;
};

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);
export default function AdminNumbers() {
  const [allNumbers, setAllNumbers] = useState<NumberInfo[]>([]);
  const [displayedNumbers, setDisplayedNumbers] = useState<NumberInfo[]>([]);
  const [filteredNumbers, setFilteredNumbers] = useState<NumberInfo[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [editingNumber, setEditingNumber] = useState<NumberInfo | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const accentColor = useColorModeValue('brand.500', 'brand.300');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
  const inputBgColor = useColorModeValue('white', 'gray.700');
  const tableHeaderBgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    fetchNumbers();
  }, []);

  const fetchNumbers = async () => {
    try {
      setIsLoading(true);
      const response = await get<NumberInfo[]>("number");
      if (response.success && response.data) {
        setAllNumbers(response.data);
        setFilteredNumbers(response.data);
        setDisplayedNumbers(response.data.slice(0, ITEMS_PER_PAGE));
      } else {
        throw new Error(response.error || 'Failed to fetch numbers');
      }
    } catch (error) {
      console.error('Error fetching numbers:', error);
      setError('Error al cargar los números. Por favor, intenta de nuevo más tarde.');
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = () => {
    const filtered = allNumbers.filter(
      (number) =>
        number.user_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        number.number.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredNumbers(filtered);
    setDisplayedNumbers(filtered.slice(0, ITEMS_PER_PAGE));
    setPage(1);
  };

  useEffect(() => {
    applyFilters();
  }, [searchTerm, allNumbers]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setDisplayedNumbers(filteredNumbers.slice(0, nextPage * ITEMS_PER_PAGE));
  };

  const handleEdit = (number: NumberInfo) => {
    setEditingNumber(number);
    onOpen();
  };

  const handleUpdate = async () => {
    if (!editingNumber) return;

    try {
      const response = await get<NumberInfo>(`number/${editingNumber.user_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(editingNumber),
      });

      if (response.success && response.data) {
        const updatedNumber = response.data;
        setAllNumbers((prevNumbers) =>
          prevNumbers.map((num) =>
            num.user_id === updatedNumber.user_id ? updatedNumber : num
          )
        );
        toast({
          title: "Número actualizado",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        throw new Error(response.error || 'Failed to update number');
      }
    } catch (error) {
      toast({
        title: "Error al actualizar el número",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (user_id: string) => {
    const isConfirmed = window.confirm("¿Estás seguro de que quieres eliminar este número?");
    if (!isConfirmed) return;

    try {
      const response = await get(`number/${user_id}`, {
        method: "DELETE",
      });

      if (response.success) {
        setAllNumbers((prevNumbers) =>
          prevNumbers.filter((num) => num.user_id !== user_id)
        );
        setFilteredNumbers((prevNumbers) =>
          prevNumbers.filter((num) => num.user_id !== user_id)
        );
        setDisplayedNumbers((prevNumbers) =>
          prevNumbers.filter((num) => num.user_id !== user_id)
        );

        toast({
          title: "Número eliminado",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error(response.error || 'Failed to delete number');
      }
    } catch (error) {
      toast({
        title: "Error al eliminar el número",
        description: error instanceof Error ? error.message : "Error desconocido",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const hasMore = useMemo(() => page * ITEMS_PER_PAGE < filteredNumbers.length, [page, filteredNumbers]);

  const NumberCard = ({ number }: { number: NumberInfo }) => (
    <Box
      borderWidth="1px"
      borderRadius="xl"
      p={4}
      mb={4}
      bg={cardBgColor}
      boxShadow="sm"
      _hover={{ boxShadow: "md" }}
      transition="all 0.2s"
    >
      <VStack align="stretch" spacing={3}>
        <HStack justify="space-between">
          <Text fontWeight="bold" fontSize="md">{number.user_id}</Text>
          <Badge colorScheme={number.verified ? "green" : "yellow"} borderRadius="full" px={2} py={1}>
            {number.verified ? "Verificado" : "No verificado"}
          </Badge>
        </HStack>
        <HStack justify="space-between" fontSize="sm">
          <Text><Icon as={FiPhone} mr={2} color={accentColor} />{number.number}</Text>
          <Text><Icon as={FiMessageCircle} mr={2} color={accentColor} />{number.channel}</Text>
        </HStack>
        <HStack justify="space-between" fontSize="sm">
          <Text><Icon as={FiRepeat} mr={2} color={accentColor} />Intentos: {number.intents}</Text>
          <Badge colorScheme={number.blocked ? "red" : "green"} borderRadius="full" px={2} py={1}>
            {number.blocked ? "Bloqueado" : "Activo"}
          </Badge>
        </HStack>
        <HStack justify="flex-end" mt={2} spacing={2}>
          <Tooltip label="Editar" hasArrow>
            <IconButton
              aria-label="Editar número"
              icon={<FiEdit2 />}
              size="sm"
              colorScheme="blue"
              variant="outline"
              onClick={() => handleEdit(number)}
              borderRadius="full"
              _hover={{ bg: 'blue.50', color: 'blue.600' }}
            />
          </Tooltip>
          <Tooltip label="Eliminar" hasArrow>
            <IconButton
              aria-label="Eliminar número"
              icon={<FiTrash2 />}
              size="sm"
              colorScheme="red"
              variant="outline"
              onClick={() => handleDelete(number.user_id)}
              borderRadius="full"
              _hover={{ bg: 'red.50', color: 'red.600' }}
            />
          </Tooltip>
        </HStack>
      </VStack>
    </Box>
  );

  if (isLoading && displayedNumbers.length === 0) {
    return (
      <Flex justify="center" align="center" minHeight="50vh">
        <Spinner size="xl" color={accentColor} thickness="4px" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Box bg={bgColor} borderRadius="lg" p={6} boxShadow="md" borderWidth="1px" borderColor={borderColor}>
        <Text color="red.500" fontWeight="medium">{error}</Text>
      </Box>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={8} align="stretch">
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Flex direction={["column", "row"]} justify="space-between" align="center" mb={6}>
            {/* <Heading as="h1" size="xl" mb={[4, 0]} color={textColor}>
              Administración de Números
            </Heading> */}
            <HStack spacing={4}>
              <InputGroup maxW={["100%", "300px"]}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color={accentColor} />
                </InputLeftElement>
                <Input
                  placeholder="Buscar por User ID o Número"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  bg={inputBgColor}
                  borderColor={borderColor}
                  _hover={{ borderColor: accentColor }}
                  _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }}
                  fontFamily="'Poppins', sans-serif"
                  fontSize="sm"
                />
              </InputGroup>
              <Button
                leftIcon={<FiRefreshCw />}
                onClick={fetchNumbers}
                colorScheme="brand"
                variant="solid"
                size="md"
                borderRadius="full"
                bg={accentColor}
                _hover={{ bg: 'brand.600' }}
                fontFamily="'Poppins', sans-serif"
                fontSize="sm"
              >
                Actualizar
              </Button>
            </HStack>
          </Flex>
          {isMobile ? (
            <VStack spacing={4} align="stretch">
              {displayedNumbers.map((number) => (
                <NumberCard key={number.user_id} number={number} />
              ))}
            </VStack>
          ) : (
            <Box overflowX="auto" borderRadius="lg" borderWidth="1px" borderColor={borderColor}>
              <Table variant="simple">
                <Thead bg={tableHeaderBgColor}>
                  <Tr>
                    <Th color={accentColor} fontWeight="bold">User ID</Th>
                    <Th color={accentColor} fontWeight="bold">Número</Th>
                    <Th color={accentColor} fontWeight="bold">Canal</Th>
                    <Th color={accentColor} fontWeight="bold">Intentos</Th>
                    <Th color={accentColor} fontWeight="bold">Verificado</Th>
                    <Th color={accentColor} fontWeight="bold">Estado</Th>
                    <Th color={accentColor} fontWeight="bold">Acciones</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {displayedNumbers.map((number) => (
                    <Tr key={number.user_id} _hover={{ bg: hoverBgColor }} transition="background-color 0.2s">
                      <Td><Text fontWeight="medium">{number.user_id}</Text></Td>
                      <Td><HStack><Icon as={FiPhone} color={accentColor} /><Text>{number.number}</Text></HStack></Td>
                      <Td><HStack><Icon as={FiMessageCircle} color={accentColor} /><Text>{number.channel}</Text></HStack></Td>
                      <Td><HStack><Icon as={FiRepeat} color={accentColor} /><Text>{number.intents}</Text></HStack></Td>
                      <Td>
                        <Badge colorScheme={number.verified ? "green" : "yellow"} borderRadius="full" px={2} py={1}>
                          {number.verified ? "Verificado" : "No verificado"}
                        </Badge>
                      </Td>
                      <Td>
                        <Badge colorScheme={number.blocked ? "red" : "green"} borderRadius="full" px={2} py={1}>
                          {number.blocked ? "Bloqueado" : "Activo"}
                        </Badge>
                      </Td>
                      <Td>
                        <HStack spacing={2}>
                          <Tooltip label="Editar" hasArrow>
                            <IconButton
                              aria-label="Editar número"
                              icon={<FiEdit2 />}
                              size="sm"
                              colorScheme="blue"
                              variant="outline"
                              onClick={() => handleEdit(number)}
                              borderRadius="full"
                              _hover={{ bg: 'blue.50', color: 'blue.600' }}
                            />
                          </Tooltip>
                          <Tooltip label="Eliminar" hasArrow>
                            <IconButton
                              aria-label="Eliminar número"
                              icon={<FiTrash2 />}
                              size="sm"
                              colorScheme="red"
                              variant="outline"
                              onClick={() => handleDelete(number.user_id)}
                              borderRadius="full"
                              _hover={{ bg: 'red.50', color: 'red.600' }}
                            />
                          </Tooltip>
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
        </MotionBox>
        {hasMore && (
          <Flex justifyContent="center" mt={6}>
            <Button
              onClick={loadMore}
              colorScheme="brand"
              size="lg"
              leftIcon={<FiPlus />}
              _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg', bgGradient: "linear(to-r, orange.500, red.600)" }}
              transition="all 0.2s"
              borderRadius="full"
              px={8}
              py={6}
              fontWeight="bold"
              fontSize="md"
              bgGradient="linear(to-r, orange.400, red.500)"
              _active={{ bgGradient: "linear(to-r, orange.600, red.700)" }}
            >
              Cargar más números
            </Button>
          </Flex>
        )}
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent borderRadius="xl" bg={bgColor}>
          <ModalHeader fontFamily="'Poppins', sans-serif" fontSize="xl" fontWeight="bold">Editar número</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel fontFamily="'Poppins', sans-serif">Número</FormLabel>
                <Input
                  value={editingNumber?.number || ""}
                  onChange={(e) =>
                    setEditingNumber((prev) =>
                      prev ? { ...prev, number: e.target.value } : null
                    )
                  }
                  fontFamily="'Inter', sans-serif"
                />
              </FormControl>
              <FormControl>
                <FormLabel fontFamily="'Poppins', sans-serif">Canal</FormLabel>
                <Input
                  value={editingNumber?.channel || ""}
                  onChange={(e) =>
                    setEditingNumber((prev) =>
                      prev ? { ...prev, channel: e.target.value } : null
                    )
                  }
                  fontFamily="'Inter', sans-serif"
                />
              </FormControl>
              <FormControl>
                <FormLabel fontFamily="'Poppins', sans-serif">Intentos</FormLabel>
                <Input
                  type="number"
                  value={editingNumber?.intents || 0}
                  onChange={(e) =>
                    setEditingNumber((prev) =>
                      prev ? { ...prev, intents: parseInt(e.target.value) } : null
                    )
                  }
                  fontFamily="'Inter', sans-serif"
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel fontFamily="'Poppins', sans-serif" mb="0">Verificado</FormLabel>
                <Switch
                  isChecked={editingNumber?.verified || false}
                  onChange={(e) =>
                    setEditingNumber((prev) =>
                      prev ? { ...prev, verified: e.target.checked } : null
                    )
                  }
                  colorScheme="brand"
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel fontFamily="'Poppins', sans-serif" mb="0">Bloqueado</FormLabel>
                <Switch
                  isChecked={editingNumber?.blocked || false}
                  onChange={(e) =>
                    setEditingNumber((prev) =>
                      prev ? { ...prev, blocked: e.target.checked } : null
                    )
                  }
                  colorScheme="brand"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={handleUpdate} fontFamily="'Poppins', sans-serif">
              Guardar
            </Button>
            <Button variant="ghost" onClick={onClose} fontFamily="'Poppins', sans-serif">
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}