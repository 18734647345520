import React, { useState } from 'react';
import { Box, VStack, Heading, Text, Button, useColorModeValue } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  const [isHovering, setIsHovering] = useState(false);
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const accentColor = useColorModeValue('orange.500', 'orange.300');

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={bgColor}
      overflow="hidden"
      position="relative"
    >
      <VStack spacing={8} zIndex={1} maxWidth="600px" textAlign="center">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Heading color={accentColor} fontSize="6xl">
            404
          </Heading>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <VStack spacing={4}>
            <Heading color={textColor} fontSize="2xl">
              ¡Ups! Te has perdido en el MineEspacio
            </Heading>
            <Text color={textColor} fontSize="lg">
              Parece que has excavado demasiado profundo y te has salido del mapa. 
              ¿Quizás necesitas actualizar tus coordenadas?
            </Text>
            <Button
              as={Link}
              to="/"
              colorScheme="orange"
              size="lg"
              mt={4}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
            >
              Volver al Lobby Principal
            </Button>
            <Text color={textColor} fontSize="sm" mt={2}>
              O conéctate a play.minelatino.com para una nueva aventura
            </Text>
          </VStack>
        </motion.div>
      </VStack>
      <AnimatePresence>
        {isHovering && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: 'none',
            }}
          >
            {[...Array(20)].map((_, i) => (
              <motion.img
                key={i}
                src={`https://minelatino.shop/wp-content/webp-express/webp-images/uploads/2021/12/cropped-Logo-ML-1.png.webp`}
                alt="Minecraft block"
                style={{
                  position: 'absolute',
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  width: '32px',
                  height: '32px',
                }}
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 800, opacity: 1 }}
                transition={{
                  duration: Math.random() * 2 + 1,
                  repeat: Infinity,
                  repeatType: 'loop',
                  ease: 'linear',
                }}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default NotFound;